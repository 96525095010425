











































































import { Component, Emit, Model, Vue, Watch } from "vue-property-decorator";
import Rules from "@/app/modules/_global/classes/Rules";
import { mask } from "vue-the-mask";
import moment from "moment";

type LocalFormData = {
  resource: {
    days: number[];
  };
};

@Component({
  components: {
    datepicker_: () => import("@/app/modules/service/builder/elements/DatePicker.vue"),
    timepicker_: () => import("@/app/modules/service/builder/elements/TimePicker.vue")
  },
  directives: {
    mask
  }
})
export default class ReservationEditForm extends Vue {
  @Model("input", { type: Object }) readonly formData!: LocalFormData;

  @Emit()
  input() {
    return this.localFormData;
  }

  @Watch("localFormData", { deep: true })
  onLocalFormDataChanged() {
    this.input();
  }

  localFormData!: object;

  dateMin = moment().format("YYYY-MM-DD");
  valid: boolean = true as boolean;

  rules = {
    required: Rules.required
  };

  constructor() {
    super();

    this.localFormData = { ...this.formData };
  }

  allowedDates(value: string) {
    return this.formData.resource.days.includes(moment(value).day());
  }
}
